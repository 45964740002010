<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "MakeWritingAHabitNotAChoice",
  components: {
    SuawMainContent,
    SuawParagraph,
    // eslint-disable-next-line vue/no-unused-components
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="MakeWritingAHabitNotAChoice">
      <SuawParagraph
        text="I’ve wanted to be a writer for almost as far back as I can remember. I’ve also struggled to establish a regular writing habit for almost that long."
      />
      <SuawParagraph
        text="In the past I’ve attributed much of my inability to write on any kind of regular schedule to a problem with procrastination. I intend to write; I just don’t get around to it as often as I want to. But I’ve begun to realize that procrastination is just the surface issue. By digging a little deeper, I think I’ve hit upon the issue that lies closer to the heart of the matter."
      />
      <SuawParagraph text="I think the problem I wrestle with is <strong>decision fatigue.</strong>" />
      <SuawParagraph
        text="The term “decision fatigue” refers to the deteriorating quality of decisions made when you have a long list of decisions to make. While it may sound like a problem that plagues only high-level executives and operators, it can frequently be a problem in our everyday lives. When you think of all the little decisions that make up an average day in modern life, then factor in the mid-level and major decisions that hang over us, it’s easy to see how anyone can experience decision fatigue."
      />
      <SuawParagraph
        text="Of course, the antidote to decision fatigue is habit. When you make something a habit, you remove the problem of deciding to do it, and more significantly, <strong>you remove the option of deciding not to do it.</strong>"
      />
      <SuawParagraph
        text="I made my greatest progress in establishing a writing habit when I joined Shut Up &amp; Write! By establishing a fixed time and place to write, I removed a few of the decisions that could trip me up. When and where I would write became non-issues, which left my brain free to focus on what I’d write. In addition, I found that having a regular place and time didn’t just open up space to focus on writing; it actually stimulated my brain towards writing. Sitting in my usual café at the usual time, drinking my latte, surrounded by some of the same faces each week often triggered an almost Pavlovian response—like flipping a switch, I clicked into writing mode. I don’t mean that I automatically turned into a writing machine, but I did often feel when I arrived for a Shut Up &amp; Write! session that I could leave other issues at the door and allow my mind the time to roam through my writing world."
      />
      <SuawParagraph
        text="The lockdown due to the COVID pandemic threw that habit into disarray. I tried to transfer that same energy and discipline to writing at home at my own desk, drinking my home-brewed coffee, but for many weeks, it just wasn’t working. It took me a while to realize what was happening: I was succumbing once again to decision fatigue."
      />
      <SuawParagraph
        text="With a much more open and unstructured schedule, when was the best time to write? Should I work at my desk or sprawl on the sofa or maybe just stay in bed and write there? Should I write directly on my laptop or maybe go old-school with a fountain pen and pad of paper? Maybe this would be a good time to get in the habit of writing with dictation software!"
      />
      <SuawParagraph text="So many choices! And every choice held the implicit option of choosing not to write at all." />
      <SuawParagraph
        text='In an effort to wrangle myself back into some kind of writing habit, I turned to Gretchen Rubin’s book, "<a href="https://gretchenrubin.com/books/better-than-before/about-the-book/" target="_blank">Better Than Before: What I Learned About Making and Breaking Habits—to Sleep More, Quit Sugar, Procrastinate Less, and Generally Build a Happier Life</a>." Rubin has done a lot of research into the subject of habits and she brings an assortment of behavioral studies into her personal assessment of what works and what doesn’t. I highly recommend reading the book, but at the very beginning Rubin lays out what she calls “The Habits Manifesto”—twelve tips for habit creation and maintenance that she derived from her research and personal experience. Of those twelve points, there are four that I’m particularly trying to embrace:'
      />
      <SuawParagraph text="1. Make it easy to do right and hard to go wrong." />
      <SuawParagraph
        text="Making a task easy is essential (for me, at least) to getting anything hard done, and let’s face it—writing is often hard. I try to make it easier in two ways. First, I decide what I’m going to work on before I sit down to write. I establish the blog post topic or the scene from my novel or completely fresh writing project I’m going to work on before I’m in front of my computer screen. That tends to minimize the amount of time I spend staring at a completely blank screen. But the second way I make it easier is also important: I allow myself to change my mind about what I’m working on. If the scene I intended to write just isn’t coming together, it’s OK for me to write something else, as long as I just keep writing."
      />
      <SuawParagraph text="2. It’s easier to change our surroundings than ourselves." />
      <SuawParagraph
        text="I may never be the kind of person for whom a writing habit comes easily. But I can change my environment to better fit a writing habit. After being a freelancer for a couple decades, I finally purchased an actual desk this year! I have a dedicated spot to write and I’ve made it as comfortable and inspiring as I can. I have some writer friends that I meet with via Zoom to spend some writing time, and I’ve also found virtual Shut Up &amp; Write! meetings to offer some of the same kind of camaraderie I got from the in-person meetings in the Before Times."
      />
      <SuawParagraph text="3. We manage what we monitor." />
      <SuawParagraph
        text='This point is important, and it particularly caught my attention because it echoes a common mantra from the business world: you can’t manage what you don’t measure. It seems to help a lot to track your writing habit and the more details you track, the better. It can be the number of words or pages written, the number of hours spent writing, or some other metric. However you measure it, write it down. In her book "<a href="http://rachelaaron.net/series.php?SID=4" target="_blank">How I Went from Writing 2,000 Words a Day to 10,000 Words a Day</a>," Rachel Aaron wrote that she kept a spreadsheet that tracked the time she started writing, the time she stopped, where she was writing, and how many words she wrote in each session. With that information, she was able to determine that there were certain times of the day, certain places, and certain lengths of time where she was more productive.'
      />
      <SuawParagraph text="4. Once we’re ready to begin, begin now." />
      <SuawParagraph
        text="As with dieting, exercising, and other somewhat painful activities, there’s no better time to start a writing habit than right now. It won’t magically become easier tomorrow or next week or next month. If anything, your resistance only grows when you put off starting. Rubin is dead-on about this. <strong>Now is the time.</strong> Stop <strong>choosing</strong> to write. Just write. Write now."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
